<template>
    <div class="login-container">
        <h2>Welcome to PwC PeopleLink</h2>
        <div v-if="errorMessage" class="error-banner">
            <i class="bi bi-exclamation-circle-fill"></i>
            {{ errorMessage }}
        </div>
        <h4>Email</h4>
        <input v-model="email" placeholder="Enter your email" />
        <h4>Password</h4>
        <input v-model="password" type="password" placeholder="Enter your password" />
        <h5>
            <router-link to="/password-reset" class="forgot-password-link">Forgot your password?</router-link>
        </h5>
        <LoginButton
            :email="email"
            :password="password"
            @login-success="handleLoginSuccess"
            @login-error="handleLoginError"
        />
        <button class="secondary-button" @click="navigateToCreateAccount">Create an Account</button>
    </div>
</template>


<script>
import LoginButton from '@/components/common/LoginButton.vue';

export default {
    name: "login-page",
    components: {
        LoginButton
    },
    data() {
        return {
            email: "", 
            password: "",
            errorMessage: "",        
        };
    },
    methods: {
        handleLoginSuccess(data) {
            console.log("Login successful:", data);
            // Route to Home Page
            this.errorMessage = "";
            // localStorage.setItem('jwtToken', data.jwt_token); // Store token securely
            this.$router.push('/home');
        },
        handleLoginError(error) {
            console.error("Login failed:", error);
            // Show error message to the user
            this.errorMessage = error;
        },
        navigateToCreateAccount() {
            this.$router.push('/register');
        },
    },
};
</script>

<style>
@import '../styles/main.scss';

</style>
