import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import Login from '../views/LoginPage.vue'
import Register from '../views/CreateAccountPage.vue'
import ForgotPassword from '../views/PasswordResetPage.vue';
import axiosClient from '../api/axiosClient.js'

const routes = [
  {
    path: '/',
    name: 'login-page',
    component: Login
  },
  {
    path: '/home',
    name: 'home-page',
    component: Home,
    meta: {requiresAuth: true},
  },
  {
    path: '/register',
    name: 'create-account-page',
    component: Register,
  },
  {
    path: '/password-reset',
    name: 'password-reset-page',
    component: ForgotPassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
      try {

          // Validate the authentication token via API
          await axiosClient.get("/auth/validate");

          // If successful, proceed to the requested route
          next();
      } catch (error) {
          console.error("Token validation failed:", error.response?.data || error.message);

          // Redirect to login page on validation failure
          next({ name: "login-page" });
      }
  } else {
      // Proceed to non-protected routes
      next();
  }
});




export default router;
