<template>
    <div class="password-reset-container">
        Password Reset
    </div>
</template>

<script>
export default {
    name:"password-reset-page"
}
</script>