<template>
    <div>
        <h1>Users</h1>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Base Office</th>
                    <th scope="col">Line of Service</th>
                    <th scope="col">Kudos</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.email">
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.grade }}</td>
                    <td>{{ user.base_office }}</td>
                    <td>{{ user.los }}</td>
                    <td>{{ user.kudos }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "UserTable",
    data() {
        return {
            users: [], // Initialise users
        };
    },
    methods: {
        async fetchUsers() {
            try {
                const apiUrl = process.env.VUE_APP_API_URL || "https://mfpeoplelink.co.uk/api";
                const response = await fetch(`${apiUrl}/users/leaderboard`); // leaderboard endpoint
                if (!response.ok) {
                    throw new Error(`Error connecting, status: ${response.status}`);
                }
                this.users = await response.json();
            } catch (error) {
                console.error("There was an error fetching users:", error);
            }
        },
    },
    mounted() {
        this.fetchUsers(); // mount component to fetch users
    },
};
</script>

<style scoped>
.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
}
th {
    background-color: #f4f4f4;
    text-align: left;
}
</style>
