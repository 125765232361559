<template>
    <div class="create-account-container">
        Create An Account
    </div>
</template>

<script>
export default {
    name:"create-account-page"
}
</script>