<template>
    <button @click="createUser">Create User</button>
  </template>
  
  <script>
  export default {
    methods: {
      async createUser() {
        try {
          const apiUrl = process.env.VUE_APP_API_URL || "https://mfpeoplelink.co.uk/api";
          await fetch(`${apiUrl}/users/create`, {
            method: "POST",
          });
          this.$emit("userCreated"); 
        } catch (error) {
          console.error("Error creating user:", error);
        }
      },
    },
  };
  </script>
  