<template>
    <div class="container">
      <h1>WELCOME TO PEOPLELINK!</h1>
      <h2>Lets get started below</h2>
      <div class="row">
        <div class="col-md-6">
          <CreateUserButton @user-created="fetchUsers"/>
        </div>
        <div class="col-md-6">
          <UserTable ref="userTable"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CreateUserButton from '../components/CreateUserButton.vue';
  import UserTable from '../components/UserTable.vue';
  
  export default {
  name: 'home-page',
  components: {
    CreateUserButton,
    UserTable,
  },
  methods: {
    fetchUsers() {
      this.$refs.userTable.fetchUsers();
    },
  },
};

  </script>
  
  <style scoped>
  .container {
    margin-top: 50px;
  }
  </style>
  