import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "https://mfpeoplelink.co.uk/api",
  withCredentials: true, // Required for cookies
});

axiosClient.interceptors.request.use(
  (config) => {
    config.withCredentials = true; // Ensure cookies are sent with every request
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosClient;
